<aside
  class="left_sidebars"
  *ngIf="
    ![
      '/profile/edit/profile',
      '/sign-in',
      '/register',
      '/forgot-password',
      '/finance/edit-billing-detail',
      '/finance/edit-bank-detail',
      '/live-show/create-live-show',
      '/finance/payment-policy',
      '/radio/create-radio'
    ].includes(currentPath)
  "
>
  <ul class="aside_list">
    <li class="cursor-pointer" [routerLink]="['/dashboard']">
      <div
        class="lsit_item"
        [ngClass]="{
          list_active: !currentRoute || currentRoute === 'dashboard'
        }"
      >
        <img
          class="list_icon"
          [src]="
            !currentRoute || currentRoute === 'dashboard'
              ? 'assets/images/static/active-dashboard.svg'
              : 'assets/images/static/dashboard.svg'
          "
        />
        <p class="item_text">Dashboard</p>
      </div>
    </li>
    <li
      class="cursor-pointer"
      *ngIf="artist?.category === 'comedians' || 'musicians' || 'podcasters'"
      [routerLink]="['/skits']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'skits' }"
      >
        <img class="list_icon" src="assets/images/static/Video.svg" />
        <p class="item_text">Videos</p>
      </div>
    </li>
    <li
      class="cursor-pointer"
      *ngIf="artist?.category === 'comedians' || 'musicians' || 'podcasters'"
      [routerLink]="['/music']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'music' }"
      >
        <img
          class="list_icon"
          [src]="
            currentRoute === 'music'
              ? 'assets/images/static/active-music.svg'
              : 'assets/images/static/music.svg'
          "
        />
        <p class="item_text">Music</p>
      </div>
    </li>
    <li
      class="cursor-pointer"
      *ngIf="artist?.category === 'comedians' || 'musicians' || 'podcasters'"
      [routerLink]="['/podcast']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'podcast' }"
      >
        <img class="list_icon" src="assets/images/static/skit.svg" />
        <p class="item_text">Podcast</p>
      </div>
    </li>
    <li
      class="cursor-pointer"
      [routerLink]="['/fan-club']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'fan-club' }"
      >
        <img
          class="list_icon"
          [src]="
            currentRoute === 'fan-club'
              ? 'assets/images/active-star.svg'
              : 'assets/images/star.svg'
          "
        />
        <p class="item_text">Fan Club</p>
      </div>
    </li>
    <li
      class="cursor-pointer"
      [routerLink]="['/live-show']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'live-show' }"
      >
        <img
          class="list_icon"
          [src]="
            currentRoute === 'live-show'
              ? 'assets/images/live_light.png'
              : 'assets/images/live_dark.png'
          "
        />

        <p class="item_text">Live Shows</p>
      </div>
    </li>
    <li
      class="cursor-pointer"
      [routerLink]="['/finance']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'finance' }"
      >
        <img class="list_icon" src="assets/images/static/finance.svg" />
        <p class="item_text">Finance</p>
      </div>
    </li>

    <li
      class="cursor-pointer"
      *ngIf="artist?.category === 'radio'"
      [routerLink]="['/radio']"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'radio' }"
      >
        <img class="list_icon" src="assets/images/static/skit.svg" />
        <p class="item_text">Radio station</p>
      </div>
    </li>

    <li
      class="cursor-pointer"
      disabled
      containerClass="custom-tooltip"
      placement="right"
    >
      <div
        class="lsit_item"
        [ngClass]="{ list_active: currentRoute === 'help' }"
        [routerLink]="['/help']"
      >
        <img class="list_icon" src="assets/images/static/help.svg" />
        <p class="item_text">Help & Contact</p>
      </div>
    </li>

    <!-- <li class="cursor-pointer logout_item" (click)="logoutUser()">
      <div class="lsit_item">
        <img class="list_icon" src="assets/images/Logout_Red.svg" />
        <p class="item_text text-danger">Logout</p>
      </div>
    </li> -->
  </ul>
</aside>
